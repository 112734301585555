import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';

function App() {

  const [text, setText] = useState('');
  const message = "COMING SOON ... ";

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= message.length) {
        setText(message.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust the speed of typing here
    return () => clearInterval(interval);
  }, [message]);


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo}  alt="logo" />
        <p>
          {text}
        </p>
      </header>
    </div>
  ); 
}

export default App;
